import Footer from "../Footer/Footer";
import {IonContent, useIonToast} from "@ionic/react";
import './Wrapper.scss';
import {useEffect} from "react";
import {StatusInfoRequest} from "../../../http-request/StatusInfo.request";
import {CurrentRoute} from "../../../../shared/utils/CurrentRoute";
import PageTransition from "../../../../shared/animations/PageTransition";

class Props {
    marginTop? = true;
}

const Wrapper: React.FC<Props> = (props) => {
    //useTitle();
    const [present, dismiss] = useIonToast();
    useEffect(() => {
        let page = CurrentRoute.GetCurrentRouteDetails()
        const subscription = StatusInfoRequest.getPageMessages(page?.pageName!).subscribe(data => {
            if (data.data.haveMessage) {
                data.data.messages.forEach(async d => {
                    let duration = 0;
                    if (!d.isPersistent) {
                        duration = 5000;
                    }
                    present({
                        message: d.message,
                        duration: duration,
                        color: (d.level === 'info') ? 'success' : (d.level === 'warning') ? 'warning' : (d.level === 'critical') ? 'danger' : 'success'
                    })
                });
            } else {
                dismiss();
            }
        });
        return () => subscription.unsubscribe();
    }, []);


    return (
        <IonContent fullscreen
                    className={(props.marginTop == undefined || props.marginTop || false) ? 'ion-content' : ''}>
            <PageTransition>
                <div className="content">
                    <div className="d-flex justify-content-center">
                        <div className="max-width">
                            {props.children}
                        </div>
                    </div>
                    <span className="footer position-relative">
                        <Footer/>
                    </span>
                </div>
            </PageTransition>
        </IonContent>
    );
}

export default Wrapper;
